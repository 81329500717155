<template>
  <el-upload
    :accept='uploadOptions.getAccept()'
    :action='action'
    :before-upload='handleBeforeUpload'
    :class='isShowPictureCard'
    :data='uploadOptions.originData'
    :headers='uploadOptions.getHeaders()'
    :limit='1'
    :on-error='handleUploadError'
    :on-exceed='handleExceed'
    :on-preview='handlePreview'
    :on-progress='uploadOptions.onProgress'
    :on-remove='handleRemove'
    :on-success='handleUploadSuccess'
    class='business-license-upload'
    list-type='picture-card'
  >
    <i class='el-icon-plus' />
    <div v-if='fileList.length === 0' slot='tip'>上传营业执照</div>
    <div v-else slot='tip' style='padding-left: 18px'>营业执照</div>
  </el-upload>
</template>

<script>
import uploadC from '@/utils/uploadC'

let uploadOptions = uploadC({
  accept: '.bmp,.jpg,.jpeg,.png'
})
export default {
  name: 'BusinessLicenseUpload',
  data () {
    return {
      uploadOptions,
      action: '',
      fileList: []
    }
  },
  computed: {
    isShowPictureCard () {
      return {
        'pictureCard-show': this.fileList.length === 0,
        'pictureCard-hide': this.fileList.length !== 0
      }
    }
  },
  methods: {
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${ files.length } 个文件，共选择了 ${ files.length + fileList.length } 个文件`)
    },
    // 都可以上传多上，用逗号隔开
    handleBeforeUpload (file) {
      if(this.uploadOptions.validateFileSize(file.size)) {
        this.$message.error('文件上传过大，请重新上传')
        return false
      } else if(this.uploadOptions.validateFileAccept(file)) {
        this.$message.error('文件上传格式错误，请重新上传')
        return false
      } else {
        let fileName = encodeURIComponent(file.name)
        // 返回的数据一段时间会失效，因此上传前都要请求一次
        let response = uploadOptions.getResponse('/api-member/fpc/ums-upload')
        let result = Object.assign({ name: fileName }, response)
        let path = result.host + '/' + result.dir + fileName
        
        this.uploadOptions.setMultipleFiles(fileName, path, file.uid)
        this.fileList = this.uploadOptions.getMultipleFiles()
        this.uploadOptions.upDateOriginData(result)
      }
    },
    handleUploadSuccess () {
      this.uploadOptions.onSuccess()
      this.$emit('uploadSuccess')
      this.$message.success('营业执照上传成功')
    },
    handleUploadError (file) {
      this.uploadOptions.onError()
      let multipleFiles = this.uploadOptions.getMultipleFiles()
      let removeIndex = multipleFiles.findIndex((item) => item.uid === file.uid)
      
      multipleFiles.splice(removeIndex, 1)
      this.fileList = multipleFiles
    },
    handleRemove (file) {
      let multipleFiles = this.uploadOptions.getMultipleFiles()
      let removeIndex = multipleFiles.findIndex((item) => item.uid === file.uid)
      
      multipleFiles.splice(removeIndex, 1)
      this.fileList = multipleFiles
    },
    handlePreview (file) {
      window.open(file.url)
    }
  },
  
  created () {
    let response = uploadOptions.getResponse('/api-member/fpc/ums-upload')
    this.action = response.host
  },
  beforeDestroy () {
    uploadOptions = uploadC({
      accept: '.bmp,.jpg,.jpeg,.png'
    })
  }
}
</script>

<style lang='scss'>
.business-license-upload {
  &.pictureCard-show {
    .el-upload--picture-card {
      display: inline-flex;
    }
  }
  
  &.pictureCard-hide {
    line-height: 1;
    
    .el-upload--picture-card {
      display: none;
    }
  }
  
  .el-upload--picture-card {
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
  }
}
</style>
