<template>
  <el-upload
    :accept='uploadOptions.getAccept()'
    :action='action'
    :before-upload='handleBeforeUpload'
    :class='isShowPictureCard'
    :data='uploadOptions.originData'
    :headers='uploadOptions.getHeaders()'
    :limit='1'
    :on-error='handleUploadError'
    :on-exceed='handleExceed'
    :on-preview='handlePreview'
    :on-remove='handleRemove'
    :on-success='handleUploadSuccess'
    class='work-card-upload'
    list-type='picture-card'>
    <i class='el-icon-plus' />
    <div v-if='fileList.length === 0' slot='tip' style='margin-top: 10px'>上传工牌/名片</div>
    <div v-else slot='tip' style='padding-left: 18px;margin-top: 2px'>工牌/名片</div>
  </el-upload>
</template>

<script>
import uploadC from '@/utils/uploadC'

let uploadOptions = uploadC({ accept: '.bmp,.jpg,.jpeg,.png' })
export default {
  name: 'BusinessLicenseUpload',
  
  data () {
    return {
      uploadOptions,
      action: '',
      fileList: [],
      filePath: null
    }
  },
  computed: {
    isShowPictureCard () {
      return {
        'pictureCard-show': this.fileList.length === 0,
        'pictureCard-hide': this.fileList.length !== 0
      }
    }
  },
  methods: {
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${ files.length } 个文件，共选择了 ${ files.length + fileList.length } 个文件`)
    },
    // 都可以上传多上，用逗号隔开
    handleBeforeUpload (file) {
      if(this.uploadOptions.validateFileSize(file.size)) {
        this.$message.error('文件上传过大，请重新上传')
        return false
      } else if(this.uploadOptions.validateFileAccept(file)) {
        this.$message.error('文件上传格式错误，请重新上传')
        return false
      } else {
        let response = this.uploadOptions.getResponse('/api-member/fpc/ums-upload')
        let result = Object.assign({ name: encodeURIComponent(file.name) }, response)
        this.uploadOptions.setMultipleFiles(
          encodeURIComponent(file.name),
          result.host + '/' + result.dir + encodeURIComponent(file.name),
          file.uid)
        this.fileList = this.uploadOptions.getMultipleFiles()
        this.uploadOptions.upDateOriginData(result)
      }
    },
    handleRemove (file) {
      let multipleFiles = this.uploadOptions.getMultipleFiles()
      let removeIndex = multipleFiles.findIndex(item => item.uid === file.uid)
      
      multipleFiles.splice(removeIndex, 1)
      this.fileList = multipleFiles
    },
    
    handleUploadError (file) {
      this.uploadOptions.onError()
      let multipleFiles = this.uploadOptions.getMultipleFiles()
      let removeIndex = multipleFiles.findIndex(item => item.uid === file.uid)
      
      multipleFiles.splice(removeIndex, 1)
      this.fileList = multipleFiles
    },
    
    handleUploadSuccess () {
      let multipleFiles = this.uploadOptions.getMultipleFiles()
      this.filePath = multipleFiles[multipleFiles.length - 1].path
      this.uploadOptions.onSuccess()
      this.$message.success('工牌/名片上传成功')
    },
    
    handlePreview (file) {
      window.open(file.url)
    }
  },
  
  created () {
    let response = uploadOptions.getResponse('/api-member/fpc/ums-upload')
    this.action = response.host
  },
  beforeDestroy () {
    uploadOptions = uploadC({
      accept: '.bmp,.jpg,.jpeg,.png'
    })
  }
  
}
</script>

<style lang='scss'>
.work-card-upload {
  line-height: 1;
  
  .el-icon-plus {
    width: 90px;
    height: 90px;
    font-size: 30px;
    color: #8c939d;
    line-height: 90px;
    justify-content: center;
    align-items: center;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    vertical-align: top;
    
    &:hover {
      border-color: #da261d;
    }
  }
  
  &.pictureCard-show {
    .el-upload--picture-card {
      display: inline-flex;
    }
  }
  
  &.pictureCard-hide {
    .el-upload--picture-card {
      display: none;
    }
  }
  
  .el-upload--picture-card {
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
  }
  
  .work-card-img {
    border-radius: 5px;
    width: 90px;
    height: 90px;
  }
  
  .tip {
    margin-left: 18px;
    margin-top: 2px;
  }
}
</style>
