<!-- 企业认证 -->
<template>
  <el-form id='enterpriseCertification_form' ref='form' :inline='true' :model='umsMemberVo' :rules='rules' class='ld-form' label-position='left' label-width='11em' size='medium'>
    <el-form-item label='公司名称：' prop='companyName'>
      <el-input v-model='umsMemberVo.companyName' placeholder='请输入公司全名' />
    </el-form-item>
    <el-form-item label='统一社会信用代码：' prop='companySocialCode'>
      <el-input v-model='umsMemberVo.companySocialCode' placeholder='请输入统一社会信用代码' />
    </el-form-item>
    <el-form-item label='公司规模：' prop='companyScope'>
			<span v-for='item in companyScopeData' :class="{ 'sign-selected': umsMemberVo.companyScope == item.id }" class='ld-span' @click="_=>{umsMemberVo.companyScope = item.id;$refs.form.validateField('companyScope');}">
				{{ item.value }}
			</span>
    </el-form-item>
    <el-form-item label='联系电话：' prop='companyPhone'>
      <el-input v-model='umsMemberVo.companyPhone' placeholder='用于核对您的相关信息'></el-input>
    </el-form-item>
    <el-form-item label='公司类型：' prop='companyType'>
			<span v-for='(item, index) in companyTypeData'
            :class="{ 'sign-selected': umsMemberVo.companyType == item.id }"
            class='ld-span'
            @click='umsMemberVo.companyType = item.id'>
				{{ item.value }}
			</span>
    </el-form-item>
    <el-form-item label='公司主营：' prop='companyMajor'>
      <el-input v-model='umsMemberVo.companyMajor' placeholder='请输入公司主要产品类型' />
    </el-form-item>
    <el-form-item label='您的职称：' prop='companyPosition'>
			<span
        v-for='(item, index) in companyPositionData'
        :class="{ 'sign-selected': umsMemberVo.companyPosition == item.id }"
        class='ld-span' @click="_=>{umsMemberVo.companyPosition = item.id;$refs.form.validateField('companyPosition');}">
				{{ item.value }}
			</span>
    </el-form-item>
    <el-form-item label='上传资料：' prop='companyLicense'>
      <div style='display: flex'>
        <business-license-upload ref='blu' @uploadSuccess='handleUploadSuccess' />
        <work-card-upload ref='wcu' style='margin-left: 20px' />
      </div>
    </el-form-item>
    <el-form-item>
      <div class='enterpriseCertification-tip'>
        <span>提示：</span>
        <div>
          <p>1、支持文件格式：png . jpeg . gif . jpg , 每个文件不能超过2MB；</p>
          <p>2、公司营业执照需上传营业执照原件或是复印件盖公章；</p>
        </div>
      </div>
    </el-form-item>
    <el-button class='enterpriseCertification-button' @click='save'>保存</el-button>
  </el-form>
</template>

<script>
import BusinessLicenseUpload from './BusinessLicenseUpload'
import WorkCardUpload from '@/views/MembershipCenter/EnterpriseCertification/Auth/WorkCardUpload'
import { getToken } from '@/utils/auth'
import { companyScopeData, companyTypeData, companyPositionData } from '../data/staticData.js'

export default {
  props: ['memberId'],
  components: {
    BusinessLicenseUpload,
    WorkCardUpload
  },
  data () {
    return {
      companyScopeData: companyScopeData(),
      companyTypeData: companyTypeData(),
      companyPositionData: companyPositionData(),
      umsMemberVo: {
        // 公司名称
        companyName: null,
        // 统一社会信用代码
        companySocialCode: null,
        // 公司规模
        companyScope: null,
        // 联系电话
        companyPhone: null,
        // 公司类型
        companyType: null,
        // 公司主营
        companyMajor: null,
        // 您的职称
        companyPosition: null,
        // 营业执照
        companyLicense: null,
        // 工牌/名片
        companyBusinessCard: null
      },
      rules: {
        companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        companySocialCode: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        companyScope: [{ required: true, message: '请选择公司规模', trigger: 'blur' }],
        companyPhone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        companyType: [{ required: true, message: '请选择公司类型', trigger: 'blur' }],
        companyMajor: [{ required: true, message: '请输入公司主营', trigger: 'blur' }],
        companyPosition: [{ required: true, message: '请选择您的职称', trigger: 'blur' }],
        companyLicense: [{ required: true, message: '请上传营业执照', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleUploadSuccess () {
      this.umsMemberVo.companyLicense = this.$refs.blu.fileList.map(item => item.path).toString()
      this.$refs.form.validateField('companyLicense')
    },
    save () {
      const companyLicenses = this.$refs.blu.fileList.map(item => item.path)
      const companyBusinessCards = this.$refs.wcu.fileList.map(item => item.path)
      const umsMemberVo = Object.assign(this.umsMemberVo, {
        memberId: this.memberId || this.$store.state.user.uid,
        companyLicense: companyLicenses.toString(),
        companyBusinessCard: companyBusinessCards.toString()
      })
      this.$refs.form.validate((valid) => {
        if(valid) {
          this.$store.dispatch('membership/enterpriseAuthentication', umsMemberVo).then(data => {
            // 通知父组件 企业认证成功
            this.$emit('enterpriseAuthSuccess', data)
          }).catch(err => {
            console.log(err)
          })
        }
      })
    }
  },
  created () {
    // if(this.$parent.enterpriseAuthDetail) {
    // 	this.umsMemberVo.companyName = this.$parent.enterpriseAuthDetail.companyName;
    // 	this.umsMemberVo.companySocialCode = this.$parent.enterpriseAuthDetail.companySocialCode;
    // 	this.umsMemberVo.companyScope = this.$parent.enterpriseAuthDetail.companyScope;
    // 	this.umsMemberVo.companyPhone = this.$parent.enterpriseAuthDetail.companyPhone;
    // 	this.umsMemberVo.companyType = this.$parent.enterpriseAuthDetail.companyType;
    // 	this.umsMemberVo.companyMajor = this.$parent.enterpriseAuthDetail.companyMajor;
    // 	this.umsMemberVo.companyPosition = this.$parent.enterpriseAuthDetail.companyPosition;
    // 	this.umsMemberVo.companyLicense = this.$parent.enterpriseAuthDetail.companyLicense;
    // 	this.umsMemberVo.companyBusinessCard = this.$parent.enterpriseAuthDetail.companyBusinessCard;
    // }
    // console.log('this.$parent.enterpriseAuthDetail', this.$parent.enterpriseAuthDetail)
  }
}
</script>

<style lang='scss'>
@import '@/styles/common.scss';

#enterpriseCertification_form {
  display: flex;
  flex-direction: column;
  margin-top: rem(48px);
  margin-left: rem(30px);
  
  label {
    font-weight: 800
  }
  
  .el-form-item__content {
    width: rem(322px);
    white-space: nowrap;
  }
  
  .ld-span {
    display: inline-block;
    width: 110px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #d4d4d4;
    border-radius: rem(4px);
    margin-right: rem(10px);
    cursor: pointer;
  }
  
  
  // 上传成功后  设置图片缩略图的大小
  .el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
    margin: 0 rem(8px) rem(8px) 0;
  }
  
  // 保存按钮
  .enterpriseCertification-button {
    @include completely-fill-button;
    width: 140px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
  }
  
  .enterpriseCertification-tip {
    display: flex;
    
    span {
      margin-right: rem(20px);
      margin-left: rem(50px);
    }
  }
}
</style>
